@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");

h1 {
  font-size: 30px;
  color: #505050;
}

h6 {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: #8c8c8c;
  background: #0c0c0c;
  width: 100%;
  margin: 0px;
  padding: 20px 0;
}

h3 {
  margin: 20px 0;
}

.time-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe7b2;
  padding: 10px;
}

.single-box {
  text-align: center;
  /* border-right: 1px solid #333333; */
  padding: 0 26px;

  &:last-child {
    border: none;
  }

  h1 {
    margin: 0px;
    font-size: 26px;
    font-weight: 400;
    color: black;
  }
  p {
    margin: 0px;
    color: black;
    white-space: nowrap;
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .single-box {
    padding: 0 15px;
  }
}
